exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-resume-en-mdx": () => import("./../../../src/pages/resume/en.mdx" /* webpackChunkName: "component---src-pages-resume-en-mdx" */),
  "component---src-pages-resume-zh-mdx": () => import("./../../../src/pages/resume/zh.mdx" /* webpackChunkName: "component---src-pages-resume-zh-mdx" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-admin-passwd-of-g-140-w-ug-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/admin-passwd-of-G-140W-UG/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-admin-passwd-of-g-140-w-ug-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-bypass-campus-network-auth-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/bypass-campus-network-auth/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-bypass-campus-network-auth-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-clash-for-android-and-tailscale-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/clash-for-android-and-tailscale/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-clash-for-android-and-tailscale-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-cross-compile-chromium-for-riscv-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/cross-compile-chromium-for-riscv/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-cross-compile-chromium-for-riscv-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-geekgame-2nd-writeup-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/geekgame-2nd-writeup/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-geekgame-2nd-writeup-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-root-iplay-40-pro-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/how-to-root-iplay40pro/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-root-iplay-40-pro-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-lineageos-and-magisk-on-iplay-40-pro-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/lineageos-and-magisk-on-iplay40pro/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-lineageos-and-magisk-on-iplay-40-pro-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-max-possible-value-of-rlimit-nofile-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/max-possible-value-of-rlimit-nofile/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-max-possible-value-of-rlimit-nofile-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-my-first-ctf-hackergame-2022-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/my-first-ctf-hackergame-2022/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-my-first-ctf-hackergame-2022-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-outlook-calendar-with-thunderbird-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/outlook-calendar-with-thunderbird/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-outlook-calendar-with-thunderbird-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-porting-arduino-ide-to-riscv-64-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/porting-arduino-ide-to-riscv64/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-porting-arduino-ide-to-riscv-64-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-programming-thoughts-2022-04-16-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/programming-thoughts/2022-04-16.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-programming-thoughts-2022-04-16-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-safe-rust-code-that-segfaults-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/safe-rust-code-that-segfaults/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-safe-rust-code-that-segfaults-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-self-hosting-services-on-android-phone-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/self-hosting-services-on-android-phone/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-self-hosting-services-on-android-phone-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-systemd-nspawn-container-for-ros-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/systemd-nspawn-container-for-ros/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-systemd-nspawn-container-for-ros-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-use-android-devices-as-cameras-in-opencv-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/use-android-devices-as-cameras-in-opencv/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-use-android-devices-as-cameras-in-opencv-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-using-katex-with-mdx-in-gatsby-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/using-katex-with-mdx-in-gatsby/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-using-katex-with-mdx-in-gatsby-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-using-ndi-to-share-screen-with-vm-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/using-ndi-to-share-screen-with-vm/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-using-ndi-to-share-screen-with-vm-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-wsl-and-gpg-card-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/wsl-and-gpg-card/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-wsl-and-gpg-card-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-yubikey-oobe-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/blog/yubikey-oobe/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-yubikey-oobe-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-notes-trace-execve-and-execveat-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/notes/trace-execve-and-execveat.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-notes-trace-execve-and-execveat-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-notes-unpack-android-ota-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/content/notes/unpack-android-ota.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-notes-unpack-android-ota-mdx" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

